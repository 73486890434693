import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuranVerses = _resolveComponent("QuranVerses")!

  return (_openBlock(), _createElementBlock("div", {
    id: "tooltip",
    role: "tooltip",
    class: "block md:w-1/2 w-3/4 lg:max-width-screen-sm h-48 overflow-y-auto bg-green-500 text-white shadow p-2 br-4 font-size-14 align-middle",
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.enteredPopper && _ctx.enteredPopper(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.leftPopper && _ctx.leftPopper(...args)))
  }, [
    (_ctx.isLoaded(_ctx.verseRange))
      ? (_openBlock(), _createBlock(_component_QuranVerses, {
          key: 0,
          verses: _ctx.verseRange.payload
        }, null, 8, ["verses"]))
      : (_ctx.payloadTooLarge(_ctx.verseRange))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "(" + _toDisplayString(_ctx.$t('global.verse_range_too_large')) + ")", 1))
        : _createCommentVNode("", true)
  ], 32))
}