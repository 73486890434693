
import { defineComponent } from 'vue';
import { isLoaded, payloadTooLarge } from '@/interfaces/RemoteData';
import QuranVerses from '@/components/commentary/QuranVerses.vue';
import { verseRange } from '@/api/verses';
import { enteredPopper, leftPopper } from './popper';

export default defineComponent({
  components: { QuranVerses },
  setup() {
    return {
      verseRange,
      payloadTooLarge,
      isLoaded,
      enteredPopper,
      leftPopper,
    };
  },
});
